import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../../backend/api";
import { Button, ImgBox, Label, SelectBox, TagBox, TagItem, TagList, TagTitle } from "./styles";
import { DownIcon } from "../../../icons";
import { useTranslation } from "react-i18next";
import { addSelectObject } from "../../../store/actions/select";
import { ErrorMessage } from "../form/styles";
import Search from "../search";
import { getValue } from "../list/functions";
import { ElementContainer } from "../elements";
import { food } from "../../../images";
import { RowContainer } from "../../styles/containers/styles";
import InfoBoxItem from "../input/info";
import { projectSettings } from "../../project/brand/project";
import SelectItem from "./item";
import { temp } from "../../project/brand";
// import { Variants, Variant } from "../../private/pages/recipe/styles";

function CustomSelect(props) {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [updateValue, setUpdateValue] = useState({});
  const [defaultValue] = useState(props.default);
  const [selectedId, setSelectedId] = useState(props.value);
  const [initialized, setInitialized] = useState(false);
  const [selectedValue, setSelectedValue] = useState(props.label);
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [radioButton] = useState(props.radioButton ?? false);
  const selectData = useSelector((state) => state.select[props.selectApi]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toggleOptions = () => {
    setOptionsVisible(!optionsVisible);
  };
  // console.log("props.value", props.value);
  const [searchValue, setSearchValue] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const handleChange = (event) => {
    if (props.apiSearch) {
      fetchData(props.updateValue, true, "", event.target.value.toString());
      setSearchKey(event.target.value);
    } else {
      setSearchValue(event.target.value);
      const filteredOptions = options.filter((option) => option.value?.toLowerCase().includes(event.target.value.toString().toLowerCase()));
      setFilteredOptions(filteredOptions);
      if (event.target.value.toString() === "") {
        setFilteredOptions([]);
      }
    }
    // clearTimeout(searchTimeoutRef.current);
  };

  const fetchData = useCallback(
    async (item = {}, force = false, name = "", searchKey = "", params = {}) => {
      if (force && props.apiType === "API") {
        const optionHandler = (data) => {
          setOptions(data);
          setInitialized(true);
          try {
            const selected = data.filter((itemValue) => itemValue.id === selectedId)[0].value;
            setSelectedValue(selected ? selected : props.placeHolder);
          } catch {}
        };
        await getData({ ...item, searchKey, limit: props.apiSearch ? 20 : 0, ...params }, `${props.selectApi}`)
          .then((response) => {
            if (response.status === 200) {
              optionHandler(response.data);
              dispatch(addSelectObject(response.data, props.selectApi));
            } else if (response.status === 404) {
              setInitialized(false);
            } else {
              setInitialized(false);
            }
          })
          .catch((error) => {
            setInitialized(false);
          });
      } else if (props.apiType === "API") {
        const optionHandler = (data) => {
          console.log(props.name, props.customClass);
          if (props.customClass?.includes("filter") && Array.isArray(props.preData)) {
            data = [...props.preData, ...data];
          }
          setOptions(data);
          setInitialized(true);
          try {
            const selected = data.filter((itemValue) => itemValue.id === selectedId)[0].value;
            setSelectedValue(selected ? selected : props.placeHolder);
          } catch {}
        };
        if (selectData) {
          optionHandler(selectData);
        } else {
          if (initialized) {
          } else {
            await getData({ ...item }, `${props.selectApi}`)
              .then((response) => {
                if (response.status === 200) {
                  optionHandler(props.selectApi);
                  dispatch(addSelectObject(response.data, props.selectApi));
                } else if (response.status === 404) {
                  setInitialized(false);
                } else {
                  setInitialized(false);
                }
              })
              .catch((error) => {
                setInitialized(false);
              });
          }
        }
      } else if (props.apiType === "CSV") {
        const options = props.selectApi.split(",").map((item) => {
          return {
            id: item.trim(),
            value: item.trim().charAt(0).toUpperCase() + item.trim().slice(1),
          };
        });

        setOptions(options);
        setInitialized(true);
        try {
          const selected = options.filter((item) => item.id === selectedId)[0].value;
          setSelectedValue(selected ? selected : props.placeHolder);
        } catch {}
      } else if (props.apiType === "JSON") {
        const options = props.selectApi;
        setOptions(options);
        setInitialized(true);
        try {
          const selected = options.filter((item) => item.id === selectedId)[0].value;
          setSelectedValue(selected ? selected : props.placeHolder);
        } catch {}
      }
    },
    [props.apiType, props.customClass, props.name, props.preData, props.selectApi, props.placeHolder, props.apiSearch, initialized, selectedId, selectData, dispatch]
  );
  const [prevValues, setPrevValues] = useState({});
  useEffect(() => {
    let values = {};
    props.params?.forEach((item) => {
      values[item.name] = item.value;
    });

    // Check if the current values are different from the previous values
    const isValuesChanged = JSON.stringify(values) !== JSON.stringify(prevValues);
    // If values have changed, save them and fetch data
    if (Object.keys(values).length > 0 && isValuesChanged) {
      setPrevValues(values);
      fetchData(values, true);
    } else {
      fetchData(values);
    }
  }, [props.selectApi, props.params, fetchData, prevValues]);

  const selectRef = useRef(null);
  useEffect(() => {
    if (props.updateOn) {
      const isObjectEqual = (obj1, obj2) => {
        const keys1 = Object.keys(obj1 ?? {});
        const keys2 = Object.keys(obj2 ?? {});
        // console.log("Keys:",keys1, keys2);
        if (keys1.length !== keys2.length) {
          return false;
        }

        for (let key of keys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }

        return true;
      };
      const equal = isObjectEqual(updateValue, props.updateValue);
      if (!equal) {
        setUpdateValue(props.updateValue);
        let values = {};
        props.params?.forEach((item) => {
          if (!item.value) {
            item.value = props.formValues?.[item.name] ?? "";
          }
          values[item.name] = item.value;
        });
        fetchData(props.updateValue, true, props.updateOn, "", values);
      }
    }
  }, [props.updateValue, updateValue, fetchData, props.updateOn, props.params, props.formValues]);
  // Calculate the position of the dropdown
  const calculateDropdownPosition = () => {
    if (selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect();
      const dropdownHeight = 200; // Example height of the dropdown
      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceAbove = rect.top;

      return spaceBelow >= dropdownHeight ? "down" : spaceAbove >= dropdownHeight ? "up" : "down";
    }
    return "down"; // Default to down if unable to calculate
  };

  const dropdownPosition = calculateDropdownPosition();
  useEffect(() => {
    function handleClick(event) {
      if (props.customClass !== "filter") {
        if (!selectRef.current.contains(event.target)) {
          const listBox = props.listBox ?? false;
          if (!listBox) {
            setOptionsVisible(false);
          }
          if (listBox) {
            setOptionsVisible(true);
            setInitialized(true);
          }
        }
      }
    }
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [props.listBox, props.customClass]);
  useEffect(() => {
    setSelectedId(props.value);
    console.log(props.value);
  }, [props.value]);
  return radioButton ? (
    <ElementContainer ref={selectRef} className="column box">
      <InfoBoxItem info={props.info} />
      {(props.showLabel ?? true) && (
        <Label theme={props.theme} className={`${!props.value?.length > 0 ? "shrink error" : "error"}`}>
          {`${t(props.label)}${props.required ? " *" : ""}`}
        </Label>
      )}
      <ElementContainer ref={selectRef} className="left">
        <InfoBoxItem info={props.info} />
        {options.length > 0
          ? (searchValue.length > 0 ? filteredOptions : options).map((option) => {
              const isSelected = option.id === selectedId;
              return (
                <SelectItem
                  key={option.id}
                  align="left"
                  t={t}
                  customClass="round"
                  label={props.displayValue ? option[props.displayValue] : option.value}
                  onChange={(event) => {
                    const listBox = props.listBox ?? false;
                    if (!listBox) {
                      toggleOptions();
                      if (selectedId === option.id) {
                        props.onSelect({ id: defaultValue, value: props.label }, props.id, props.type);
                        setSelectedValue(props.label);
                        setSelectedId(defaultValue);
                      } else {
                        props.onSelect(option, props.id, props.type);
                        setSelectedValue(option.value);
                        setSelectedId(option.id);
                      }
                    } else {
                      props.onSelect(option, props.id, props.type);
                      setSelectedValue(option.value);
                      setSelectedId(option.id);
                    }
                  }}
                  checked={isSelected}
                >
                  {props.displayValue ? option[props.displayValue] : option.value}
                </SelectItem>
              );
            })
          : null}
      </ElementContainer>
      {props.error?.length > 0 && <ErrorMessage className={`${props.info?.length > 0 ? "info" : ""} ${projectSettings.formInputView}`} dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>}
    </ElementContainer>
  ) : (
    <SelectBox key={props.key} theme={props.theme} className={`custom-select ${props.listBox ? "list-box" : ""} ${optionsVisible ? "open" : "close"} ${props.customClass} ${props.dynamicClass}`} ref={selectRef}>
      {(props.showLabel ?? true) && (
        <Label theme={props.theme} className={`${!props.value?.length > 0 ? "shrink error" : "error"}`}>
          {`${t(props.label)}${props.required ? " *" : ""}`}
        </Label>
      )}
      <InfoBoxItem customClass={"info-select"} info={props.info} />

      <button className={`${selectedId !== null && selectedId?.length !== 0 ? "has" : ""}`} onClick={toggleOptions}>
        <span>
          {/* {props.iconImage && (
            <ImgBox
              onError={(e) => {
                e.target.src = temp; // Hide the image on error
              }}
              src={process.env.REACT_APP_CDN + (props.iconImage.collection.length > 0 ? option[props.iconImage.collection]?.[props.iconImage.item] ?? "" : option[props.iconImage.item])}
            />
          )} */}
          {`${props.value?.length === 0 ? `${t(props.label)}${props.required ? " *" : ""}` : `${t(selectedValue)}`}`}
        </span>
        <DownIcon className="down" />
      </button>

      <ul className={`options ${optionsVisible ? dropdownPosition : "hide"}`}>
        {((optionsVisible && initialized) || (initialized && props.listBox)) && (
          <>
            {props.search && options.length > 8 && <Search active={true} key={`search-inside${props.key}`} className="select" title="Search" theme={props.theme} placeholder="Search" value={searchValue} onChange={handleChange} />}
            {props.apiSearch && <Search active={true} key="search-api-2" className={"select " + props.customClass} title="Search" theme={props.theme} placeholder={`Search ${props.label}`} value={searchKey} onChange={handleChange} />}
            {options.length > 0 &&
              (searchValue.length > 0 ? filteredOptions : options).map((option) => (
                <li
                  value={option.id === selectedId}
                  className={option.id === selectedId ? "selected" : ""}
                  key={option.id}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (option.id !== selectedId) {
                      const listBox = props.listBox ?? false;
                      if (!listBox) {
                        toggleOptions();
                        if (selectedId === option.id) {
                          props.onSelect({ id: defaultValue, value: props.label }, props.id, props.type);
                          setSelectedValue(props.label);
                          setSelectedId(defaultValue);
                        } else {
                          props.onSelect(option, props.id, props.type);
                          setSelectedValue(option.value);
                          setSelectedId(option.id);
                        }
                      } else {
                        props.onSelect(option, props.id, props.type);
                        setSelectedValue(option.value);
                        setSelectedId(option.id);
                      }
                    }
                  }}
                >
                  {props.tags ? (
                    <TagBox>
                      {props.iconImage && (
                        <ImgBox
                          onError={(e) => {
                            e.target.src = food; // Hide the image on error
                          }}
                          src={process.env.REACT_APP_CDN + (props.iconImage.collection.length > 0 ? option[props.iconImage.collection]?.[props.iconImage.item] ?? "" : option[props.iconImage.item])}
                        />
                      )}
                      <RowContainer>
                        <TagItem className="title">{props.displayValue ? t(option[props.displayValue]) : t(option.value)}</TagItem>
                        <TagList>
                          {props.tags.map((tag) => (
                            <React.Fragment key={tag.item}>
                              <TagTitle>{`${tag.title}`}</TagTitle>
                              <TagItem className={tag.type}>{getValue(tag, tag.collection.length > 0 ? option[tag.collection]?.[tag.item] ?? "" : option[tag.item])}</TagItem>
                            </React.Fragment>
                          ))}
                        </TagList>
                      </RowContainer>
                    </TagBox>
                  ) : props.displayValue ? (
                    <div className="box">
                      {props.iconImage && (
                        <ImgBox
                          onError={(e) => {
                            e.target.src = food; // Hide the image on error
                          }}
                          src={process.env.REACT_APP_CDN + (props.iconImage.collection.length > 0 ? option[props.iconImage.collection]?.[props.iconImage.item] ?? "" : option[props.iconImage.item])}
                        />
                      )}
                      {t(option[props.displayValue])}
                    </div>
                  ) : (
                    <div className="box">
                      {props.iconImage && (
                        <ImgBox
                          onError={(e) => {
                            e.target.src = temp; // Hide the image on error
                          }}
                          src={process.env.REACT_APP_CDN + (props.iconImage.collection.length > 0 ? option[props.iconImage.collection]?.[props.iconImage.item] ?? "" : option[props.iconImage.item])}
                        />
                      )}
                      {t(option.value)}
                    </div>
                  )}

                  {props.viewButton && (
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                        props.viewButton?.callback(option);
                      }}
                    >
                      View Menu
                    </Button>
                  )}
                </li>
              ))}
            {props.addNew && (
              <li
                onClick={() => {
                  alert("yes");
                }}
              >
                {t(props.addNew)}
              </li>
            )}
            {initialized && options.length === 0 && (
              <li
                onClick={() => {
                  fetchData(props.updateValue, true, props.updateOn);
                }}
              >
                No Data Found. Click here to Refresh Data!
              </li>
            )}
          </>
        )}
      </ul>

      {props.error?.length > 0 && <ErrorMessage className={`${props.info?.length > 0 ? "infomargin" : "margin"} ${projectSettings.formInputView}`} dangerouslySetInnerHTML={{ __html: props.error }}></ErrorMessage>}
    </SelectBox>
  );
}

export default CustomSelect;
