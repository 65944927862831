import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Td, Tr, AddButton, ButtonPanel, Filter, Filters, ToggleContainer, ToggleInput, ToggleSlider, NoData, FilterBox, More, Actions, Title, DataItem, ToolTipContainer, Head, TrBody, TableView, TrView, ThView, TdView, TableContaner, ProfileImage, ListContainer, PageNumber, ListContainerData, ListContainerBox, Count, ArrowPagination } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { RowContainer } from "../../styles/containers/styles";
import { AddIcon, GetIcon } from "../../../icons";
import { useNavigate } from "react-router-dom";
import { deleteData, getData, postData, putData } from "../../../backend/api";
import CrudForm from "./create";
import { addPageObject } from "../../../store/actions/pages";
import FormInput from "../input";
import Manage from "./manage";
import Loader from "../loader";
import Search from "../search";
import SubPage from "./subPage";
import DateRangeSelector from "../daterange";
import * as xlsx from "xlsx";
import { ToolTip } from "../../styles/list/styles";
import { dateFormat, dateTimeFormat } from "../functions/date";
import { convertMinutesToHHMM, getValue } from "./functions";
import Popup, { DisplayInformations } from "./popup";
import Print from "./print/print";
import Highlight from "./highlight";
import Editable from "./editable";
import Details from "./details";
import PopupView from "../popupview";
import { TabContainer } from "./popup/styles";
import { food } from "../../../images";
import ImagePopup from "./image";
import moment from "moment";
import FileItem from "./file";
import { Button as EButton } from "../elements";
import { useTranslation } from "react-i18next";
import { Combination } from "./details/combination";
import UploadStatusDisplay from "./UploadStatusDisplay";
import { v4 as uuidv4 } from "uuid"; // You'll need to install the uuid package
import SearchMain from "../searchMain";
const SetTd = (props) => {
  if (props.viewMode === "table") {
    return <TdView {...props}></TdView>;
  } else {
    return <Td {...props}></Td>;
  }
};
const SetTr = (props) => {
  if (props.viewMode === "table") {
    return <TrView {...props}></TrView>;
  } else {
    return <Tr {...props}></Tr>;
  }
};
const ListTable = ({
  datefilterType = [],
  editCondition = null,
  isSingle = false,
  isPopup = false,
  icon,
  popupMode = "medium",
  showInfo = true,
  customProfileSource = false,
  orientation = "portrait",
  profileImage,
  fileSource = "",
  displayColumn = "single",
  printPrivilege = false,
  searchPrivilege = false,
  formMode = "single",
  parentReference = "_id",
  referenceId = 0,
  actions = [],
  api,
  setMessage,
  attributes = [],
  exportPrivilege = false,
  addPrivilege = true,
  delPrivilege = true,
  updatePrivilege = true,
  clonePrivilege = false,
  shortName = "Item",
  itemTitle = {
    type: "text",
    name: "title",
  },
  highlight = null,
  datefilter = false,
  preFilter = {},
  viewMode = "list",
  popupMenu = "horizontal",
  enableViewItem = false,
}) => {
  const [sessionId, setSessionId] = useState(null);
  const [filteredActions] = useState(actions.filter((item) => item.type === "callback" && item.element === "button"));
  const userData = useSelector((state) => state.pages);
  const company = useSelector((state) => state.company);
  const previousCompanyRef = useRef();
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 600px)").matches);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.matchMedia("(max-width: 600px)").matches);
    };

    // Listen for changes in screen width
    const mediaQuery = window.matchMedia("(max-width: 600px)");
    mediaQuery.addEventListener("change", updateIsMobile);

    // Initial check
    updateIsMobile();

    // Cleanup
    return () => {
      mediaQuery.removeEventListener("change", updateIsMobile);
    };
  }, []);
  const [users, setUsers] = useState({
    data: null,
    isLoading: true,
    error: null,
  });
  useEffect(() => {
    const alldata = userData[`${api}`] ?? {
      data: null,
      isLoading: true,
      error: null,
    };
    setLoaderBox(alldata.isLoading);
    setUsers(alldata);
  }, [userData, api]);

  const [showSublist, setShowSubList] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [currentApi] = useState(`${api}`);
  const [subAttributes, setSubAttributes] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [showPageCount, setShowPageCount] = useState(false);
  const [shoFilter, setShowFilter] = useState(false);
  const [count, setCount] = useState(0);
  const [editable, setEditable] = useState({});
  const [reset, setReset] = useState(0);
  const themeColors = useSelector((state) => state.themeColors);
  const selectedMenuItem = useSelector((state) => state.selectedMenu);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  /**
   * Function to set the showLoader state.
   * @param {boolean} status The status of the loader.
   */
  const setLoaderBox = (status) => {
    setShowLoader(status);
  };
  //  const [isMobile, setIsMobile] = useState(
  //   window.matchMedia("(max-width: 600px)").matches
  //  );

  //  useEffect(() => {
  //   const updateIsMobile = () => {
  //    const newIsMobile = window.matchMedia("(max-width: 600px)").matches;
  //    setIsMobile((prevIsMobile) => {
  //     if (prevIsMobile !== newIsMobile) {
  //      return newIsMobile;
  //     }
  //     return prevIsMobile;
  //    });
  //   };

  //   // Listen for changes in screen width
  //   const mediaQuery = window.matchMedia("(max-width: 600px)");
  //   mediaQuery.addEventListener("change", updateIsMobile);

  //   // Initial check
  //   updateIsMobile();

  //   // Cleanup
  //   return () => {
  //    mediaQuery.removeEventListener("change", updateIsMobile);
  //   };
  //  }, []);
  const { t } = useTranslation();
  // processing attributes
  const [initialized, setInitialized] = useState(false);
  const [prevCrud, setPrevCrud] = useState("");
  const [formInput, setFormInput] = useState([]);
  const [errroInput, setErrorInput] = useState([]);
  const [addValues, setAddValues] = useState({});
  const [updateId, setUpdateId] = useState("");
  const [updateValues, setUpdateValues] = useState({});
  const [udpateView, setUpdateView] = useState(() => {});
  const [hasFilter, setHasFilter] = useState(false);
  const [sortView, setSortView] = useState();
  const [tempTextSearch, setTempTextSearch] = useState();
  const [textSearch, setTextSearch] = useState();
  const [filterView, setFilterView] = useState(
    referenceId !== 0
      ? {
          [parentReference]: referenceId,
          ...preFilter,
        }
      : {
          ...preFilter,
        }
  );

  useEffect(() => {
    const addValuesTemp = {
      addValues: {},
      updateValues: {},
      viewValues: {},
      errorValues: {},
      filterValues: {},
      sortView: {},
      textSearchView: {},
    };
    // let tempFilter = false;
    let date = new Date();
    attributes.forEach((item) => {
      if (item.type === "checkbox") {
        let bool = JSON.parse(item.default === "false" || item.default === "true" ? item.default : "false");
        if (item.add) {
          addValuesTemp.addValues[item.name] = bool;
        }
        addValuesTemp.updateValues[item.name] = bool;
      } else if (item.type === "datetime" || item.type === "date" || item.type === "time") {
        addValuesTemp.addValues[item.name] = date.toISOString();
        if (item.add) {
          addValuesTemp.addValues[item.name] = item.default === "empty" ? "" : moment(item.default).isValid() ? moment(item.default).toISOString() : date.toISOString();
          // addValuesTemp.updateValues[item.name] = date.toISOString();
        }
        if (item.type === "date" && (item.filter ?? false) === true) {
          addValuesTemp.filterValues[item.name] = date.toISOString();
          // tempFilter = true;
        }
      } else if (item.type === "image" || item.type === "file") {
        if (item.add) {
          addValuesTemp.addValues[item.name] = [];
        }
        if (item.update) {
          addValuesTemp.updateValues[item.name] = [];
        }
      } else if (item.type === "multiSelect") {
        if (item.add) {
          addValuesTemp.addValues[item.name] = Array.isArray(item.default) ? item.default : [];
        }
        if (item.update) {
          addValuesTemp.updateValues[item.name] = [];
        }
      } else {
        if (item.add) {
          addValuesTemp.addValues[item.name] = item.default;
        }
        addValuesTemp.updateValues[item.name] = item.default;
        if (item.type === "select") {
          addValuesTemp.filterValues[item.name] = item.default;
          // tempFilter = true;
        }
      }
      if (item.sort ?? false) {
        addValuesTemp.sortView[item.name] = addValuesTemp.defaultSort ?? "";
      }
      if (item.type === "text" && (item.sort ?? false)) {
        addValuesTemp.textSearchView[item.name] = "";
      }
      addValuesTemp.errorValues[item.name] = "";
      addValuesTemp.filterValues["searchkey"] = "";
    });
    if (referenceId !== 0) {
      addValuesTemp.filterValues[parentReference] = referenceId;
    }
    setFormInput(attributes);
    setAddValues(addValuesTemp.addValues);
    setErrorInput(addValuesTemp.errorValues);
    setUpdateValues(addValuesTemp.updateValues);
    setFilterView((prevFilterView) => {
      return {
        ...prevFilterView,
        ...addValuesTemp.filterValues,
      };
    });
    setSortView(addValuesTemp.sortView);
    setTextSearch(addValuesTemp.textSearchView);
    setTempTextSearch(addValuesTemp.textSearchView);
    // setFilter(tempFilter);
    setInitialized(true);
  }, [attributes, dispatch, setPrevCrud, prevCrud, setFormInput, setAddValues, setUpdateValues, setFilterView, setSortView, parentReference, referenceId]);

  // end processing attributes
  useEffect(() => {
    //setLoaderBox(users.isLoading);
    if (currentIndex === 0 && users.data?.count) {
      setCount(users.data.filterCount);
      // setTotalCount(users.data.totalCount);
    }
  }, [users, currentIndex]);

  const ResetFilter = () => {
    const addValuesTemp = {
      filterValues: {},
      sortView: {},
    };
    // let tempFilter = false;
    let date = new Date();
    attributes.forEach((item) => {
      if (item.type === "datetime" || item.type === "date" || item.type === "time") {
        if (item.type === "date" && (item.filter ?? false) === true) {
          addValuesTemp.filterValues[item.name] = date.toISOString();
          // tempFilter = true;
        }
      } else {
        if (item.type === "select") {
          addValuesTemp.filterValues[item.name] = item.default;
          // tempFilter = true;
        }
      }
      addValuesTemp.filterValues["searchkey"] = "";
    });
    if (referenceId !== 0) {
      addValuesTemp.filterValues[parentReference] = referenceId;
    }
    setFilterView((prevFilterView) => {
      return {
        ...prevFilterView,
        ...addValuesTemp.filterValues,
      };
    });
    setSortView(addValuesTemp.sortView);
  };
  useEffect(() => {
    if (initialized) {
      dispatch(addPageObject(currentApi, currentIndex, { ...filterView, sorting: sortView, searchkey: textSearch }, perPage));
    }
  }, [initialized, currentApi, currentIndex, dispatch, filterView, sortView, perPage, textSearch]);

  useEffect(() => {
    setPageNumber(Math.ceil((currentIndex + 1) / perPage));
  }, [count, currentIndex, perPage]);
  const refreshView = (currentIndex) => {
    try {
      dispatch(addPageObject(currentApi, currentIndex, { ...filterView, sorting: sortView, searchkey: textSearch }, perPage));
    } catch {}
  };

  useEffect(() => {
    if (previousCompanyRef.current !== undefined && company !== previousCompanyRef.current) {
      // Company has changed, so refresh the view
      dispatch(addPageObject(currentApi, currentIndex, { ...filterView, sorting: sortView, searchkey: textSearch }, perPage));
    }
    // Update the ref with the current company value
    previousCompanyRef.current = company;
  }, [company, currentApi, currentIndex, dispatch, filterView, perPage, sortView, textSearch]);
  const refreshUpdate = (refresh = true, index = 0, update = {}) => {
    try {
      if (refresh) {
        dispatch(addPageObject(currentApi, currentIndex, { ...filterView, sorting: sortView, searchkey: textSearch }, perPage));
      } else {
        const usetTemp = {
          ...users,
        };
        const usetDataTemp = [...usetTemp.data.response];
        const current = usetDataTemp[index];
        console.log(current);
        usetDataTemp[index] = {
          ...current,
          ...update,
        };
        console.log(update, usetDataTemp[index]);
        usetTemp.data.response = usetDataTemp;
        setUsers(usetTemp);
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [detailView] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const [printData, setPrintData] = useState([]);
  const [openData, setOpenData] = useState({});
  //crud functions
  const [isCreating, setIsCreating] = useState(false);
  const isCreatingHandler = (value, callback) => {
    if (isCreating) {
      setUpdateView(() => callback);
      setIsCreating(false);
      navigate({}, "", window.location.pathname);
    } else {
      window.location.hash = "add";
      setIsCreating(true);
    }
  };
  const [isEditing, setIsEditing] = useState(false);
  const isEditingHandler = (value, callback, titleValue, clone = false) => {
    setLoaderBox(true);
    if (!isEditing) {
      if (!clone) {
        setUpdateView(() => callback);
        let updateValuesTemp = {};
        setUpdateId(value._id);
        console.log({ value });
        formInput.forEach((item) => {
          let itemValue = item.collection?.length > 0 && item.showItem?.length > 0 ? value[item.collection]?.[item.showItem] : value[item.name] ?? "";
          if (item.showSubItem) {
            itemValue = item.collection?.length > 0 && item.showItem?.length > 0 ? value[item.collection]?.[item.showItem]?.[item.showSubItem] ?? "" : value[item.name];
          }
          if (item.update) {
            if (item.type === "checkbox") {
              let bool = value[item.name]?.toString() === "true" ? true : false;
              updateValuesTemp[item.name] = bool;
            } else if (item.type === "number") {
              updateValuesTemp[item.name] = updateValuesTemp[item.name] = parseFloat(value[item.name]);
            } else if (item.type === "select") {
              updateValuesTemp[item.name] = typeof value[item.name] === "undefined" ? "" : typeof value[item.name] === "string" || typeof value[item.name] === "number" ? value[item.name] : value[item.name]?._id ? value[item.name]._id : "";
            } else if (item.type === "multiSelect") {
              try {
                if (item.apiType === "API") {
                  updateValuesTemp[item.name] = value[item.name].map((obj) => obj._id);
                } else {
                  updateValuesTemp[item.name] = value[item.name].map((obj) => obj);
                }
              } catch (error) {
                updateValuesTemp[item.name] = [];
              }
            } else if (item.type === "image") {
              updateValuesTemp["old_" + item.name] = value[item.name] ? value[item.name] : "";
              updateValuesTemp[item.name] = [];
            } else {
              updateValuesTemp[item.name] = itemValue ? itemValue : "";
            }
          }
        });
        updateValuesTemp["_id"] = value._id;
        updateValuesTemp["clone"] = clone;
        updateValuesTemp["_title"] = titleValue;
        console.log(updateValuesTemp);
        setUpdateValues(updateValuesTemp);
        setIsEditing(true);
        window.location.hash = "edit";
      } else {
        updateHandler({
          id: value._id,
          _title: titleValue,
          clone: true,
        });
      }
    } else {
      setUpdateId("");
      navigate({}, "", window.location.pathname);
      setIsEditing(false);
    }
    setLoaderBox(false);
  };
  const deleteHandler = async (item, id = "") => {
    await deleteData(
      {
        id,
      },
      currentApi,
      dispatch,
      navigate
    )
      .then((response) => {
        if (response.status === 200) {
          if (response.customMessage?.length > 0) {
            setMessage({
              type: 1,
              content: response.customMessage,
              proceed: "Okay",
            });
          } else {
            setMessage({
              type: 1,
              content: `The '${item.title ? item.title : shortName}' deleted successfully!`,
              proceed: "Okay",
              icon: "deleted",
            });
          }
          setCount((count) => count - 1);
          setIsCreating(false);
          refreshView(currentIndex);
          // udpateView(0);
        } else if (response.status === 404) {
          if (response.customMessage?.length > 0) {
            setMessage({
              type: 1,
              content: response.customMessage,
              proceed: "Okay",
              icon: "error",
            });
          } else {
            setMessage({
              type: 1,
              content: "User not found!",
              proceed: "Okay",
              icon: "error",
            });
          }
        } else {
          if (response.customMessage?.length > 0) {
            setMessage({
              type: 1,
              content: response.customMessage,
              proceed: "Okay",
            });
          } else {
            setMessage({
              type: 1,
              content: "Something went wrong!",
              proceed: "Okay",
              icon: "error",
            });
          }
        }
        setLoaderBox(false);
      })
      .catch((error) => {
        setMessage({
          type: 1,
          content: error.message + "Something went wrong!",
          proceed: "Okay",
          icon: "error",
        });
        setLoaderBox(false);
      });
  };
  const [action, setActions] = useState([]);
  const openAction = (item, data) => {
    // Actions Window
    setActions({
      item,
      data,
    });
    // setMessage({ type: 1, content: item.title + " / " + data.ID, proceed: "Okay" });
  };
  const submitHandler = async (data) => {
    setLoaderBox(true);

    const saveData =
      referenceId === 0
        ? {
            ...data,
          }
        : {
            ...data,
            [parentReference]: referenceId,
          };

    // Filter and remove keys that have "*Array"
    const filteredData = Object.keys(saveData).reduce((acc, key) => {
      if (!key.includes("Array")) {
        acc[key] = saveData[key];
      }
      return acc;
    }, {});
    const randomId = uuidv4(); // Generate a random UUID
    // setSessionId(randomId);

    await postData({ ...preFilter, ...filteredData, sessionId: randomId }, currentApi)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.customMessage?.length > 0) {
            setMessage({
              type: 1,
              content: response.customMessage,
              proceed: "Okay",
            });
          } else {
            setMessage({
              type: 1,
              content: `The '${shortName}' saved successfully!`,
              proceed: "Okay",
              icon: "success",
            });
          }
          setIsCreating(false);
          setCurrentIndex(0);
          refreshView(0);
          // udpateView(0);
        } else if (response.status === 404) {
          if (response.customMessage?.length > 0) {
            setMessage({
              type: 1,
              content: response.customMessage,
              proceed: "Okay",
            });
          } else {
            setMessage({
              type: 1,
              content: "User not found!",
              proceed: "Okay",
              icon: "error",
            });
          }
        } else {
          console.log(response);
          if (response.customMessage?.length > 0) {
            setMessage({
              type: 1,
              content: response.customMessage,
              proceed: "Okay",
            });
          } else {
            setMessage({
              type: 1,
              content: "Something went wrong!",
              proceed: "Okay",
              icon: "error",
            });
          }
        }
        setSessionId(null);
        setLoaderBox(false);
      })
      .catch((error) => {
        console.log(error);
        setMessage({
          type: 1,
          content: error.message + "Something went wrong!",
          proceed: "Okay",
          icon: "success",
        });
        setSessionId(null);
        setLoaderBox(false);
      });
  };
  useEffect(() => {
    if (users?.data?.response) {
      const data = users?.data?.response.find((item) => item._id === updateId);
      if (data) {
        setOpenData((prev) => ({
          ...prev,
          data: data,
        }));
        setSubAttributes((prev) => ({
          ...prev,
          data: data,
        }));
      }
    }
  }, [users, isEditing, updateId, setOpenData, setSubAttributes]);
  const updateHandler = async (data) => {
    setLoaderBox(true);
    data = {
      ...data,
      id: updateId,
    };
    await putData(data, `${currentApi}`)
      .then((response) => {
        if (response.status === 200) {
          if (response.customMessage?.length > 0) {
            setMessage({
              type: 1,
              content: response.customMessage,
              proceed: "Okay",
            });
          } else {
            setMessage({
              type: 1,
              content: `The '${data._title ?? shortName}' ${data.clone ? "cloned" : "updated"} successfully!`,
              proceed: "Okay",
              icon: "success",
            });
          }
          refreshView(currentIndex);
          setIsEditing(false);
        } else if (response.status === 404) {
          if (response.customMessage?.length > 0) {
            setMessage({
              type: 1,
              content: response.customMessage,
              proceed: "Okay",
            });
          } else {
            setMessage({
              type: 1,
              content: "User not found!",
              proceed: "Okay",
              icon: "error",
            });
          }
        } else {
          if (response.customMessage?.length > 0) {
            setMessage({
              type: 1,
              content: response.customMessage,
              proceed: "Okay",
            });
          } else {
            setMessage({
              type: 1,
              content: "Something went wrong!",
              proceed: "Okay",
              icon: "error",
            });
          }
        }
        setLoaderBox(false);
      })
      .catch((error) => {
        alert(error);
        setLoaderBox(false);
      });
  };

  const filterChange = (option, name, type) => {
    const updateValue = {
      ...filterView,
      [name]: type === "select" ? option.id : type === "date" ? option?.toISOString() : "",
    };
    setFilterView(updateValue);
    // updating the form values
  };

  const dateRangeChange = (item) => {
    const startDate = new Date(item?.startDate);
    startDate.setHours(0, 0, 0, 0); // Set start date to 00:00

    const endDate = new Date(item?.endDate);
    endDate.setHours(23, 59, 59, 999); // Set end date to 23:59
    const udpateValue = {
      ...filterView,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    };
    // updating the formm values
    setFilterView(udpateValue);
  };
  const closeManage = () => {
    setActions([]);
  };
  const TableRowWithActions = React.memo(({ attributes, data, slNo }) => {
    selectRef.current[slNo] = useRef(null);
    combinationRef.current[slNo] = useRef(null);
    const titleValue = (itemTitle.collection?.length > 0 ? (data[itemTitle.collection] ? data[itemTitle.collection][itemTitle.name] : "NIl") : data[itemTitle.name]) ?? shortName;
    const signleRecord = viewMode === "list" || viewMode === "subList" || viewMode === "table" || viewMode === "files" ? false : true;
    // data[attribute.name]?.title ? data[attribute.name]?.title : data[attribute.name]?.toString()

    const ActionDiv = (
      <React.Fragment key={`actions-${shortName}-${data.ID}`}>
        {actions.map((item, index) => {
          return (
            item.element !== "button" &&
            (item.type === "callback" ? (
              <More
                className="callBack"
                theme={themeColors}
                onClick={(event) => {
                  event.stopPropagation();
                  item.callback(item, data, refreshUpdate, slNo);
                }}
              >
                <GetIcon icon={item.icon} />
                <span>{t(item.title)}</span>
              </More>
            ) : (
              <ToggleContainer key={`${item.id}-${data.ID}`}>
                <ToggleInput
                  type="checkbox"
                  checked={data[item.id]}
                  onChange={async (event) => {
                    // item.callback(item, data);
                    setLoaderBox(true);
                    await postData(
                      {
                        status: event.target.checked,
                      },
                      `${item.api}/${data.ID}`,
                      dispatch,
                      navigate
                    )
                      .then((response) => {
                        if (response.status === 200) {
                          if (response.data?.message) {
                            setMessage({
                              type: 1,
                              content: response.data?.message,
                              proceed: "Okay",
                            });
                          }
                          //
                          refreshView();
                          // setIsEditing(false);
                        } else if (response.status === 404) {
                          refreshView();
                          setMessage({
                            type: 1,
                            content: "Something Went Wrong!",
                            proceed: "Okay",
                          });
                        } else {
                          refreshView();
                          setMessage({
                            type: 1,
                            content: "Something Went Wrong!",
                            proceed: "Okay",
                          });
                        }
                        // setLoaderBox(false);
                      })
                      .catch((error) => {
                        alert(error);
                        // setLoaderBox(false);
                      });
                  }}
                />
                <ToggleSlider />
              </ToggleContainer>
            ))
          );
        })}
        {/* {!signleRecord && (
          <>
            <More
              theme={themeColors}
              onClick={(event) => {
                event.stopPropagation();
                setIsOpen(true);
                setOpenData({ actions, attributes, data });
                setSubAttributes({ actions, attributes, data });
              }}
            >
              <GetIcon icon={"open"}></GetIcon>
            </More>
          </>
        )} */}
        {updatePrivilege &&
          (editCondition ? (
            data[editCondition.when] === editCondition.match && (
              <More
                theme={themeColors}
                onClick={(event) => {
                  event.stopPropagation();
                  isEditingHandler(data, udpateView, titleValue);
                }}
              >
                <GetIcon icon="edit" />
              </More>
            )
          ) : (
            <More
              theme={themeColors}
              onClick={(event) => {
                event.stopPropagation();
                isEditingHandler(data, udpateView, titleValue);
              }}
            >
              <GetIcon icon="edit" />
            </More>
          ))}
        {signleRecord && (
          <More
            theme={themeColors}
            onClick={(event) => {
              event.stopPropagation();
              // refreshView(currentIndex);
              setReset((re) => re + 1);
              setFilterView(
                referenceId !== 0
                  ? {
                      [parentReference]: referenceId,
                      ...preFilter,
                    }
                  : {
                      ...preFilter,
                    }
              );
            }}
          >
            <GetIcon icon={"reload"}></GetIcon>
          </More>
        )}
        {filteredActions?.length > 0 && (
          <ToolTipContainer
            ref={selectRef.current[slNo]}
            onClick={(event) => {
              event.stopPropagation();
              setCurrentAction(data.ID);
            }}
          >
            <More
              theme={themeColors}
              style={{
                color: "#F2A900",
              }}
              className={currentAction === data.ID ? `active` : ``}
            >
              <GetIcon icon={"dots"}></GetIcon>
            </More>
            <ToolTip className={currentAction === data.ID ? `actions` : `actions hide`}>
              <Actions>
                {clonePrivilege && (
                  <Button
                    theme={themeColors}
                    key={`clone-${data.ID}`}
                    onClick={(event) => {
                      event.stopPropagation();
                      setUpdateId(data.ID);
                      setMessage({
                        type: 2,
                        content: `Do you want to clone '${
                          getValue(
                            {
                              type: itemTitle.type ?? "text",
                            },
                            titleValue
                          )
                            ? getValue(
                                {
                                  type: itemTitle.type ?? "text",
                                },
                                titleValue
                              )
                            : "Item"
                        }'?`,
                        proceed: "Clone",
                        onProceed: async () => {
                          await updateHandler({
                            cloneId: data.ID,
                            _title: titleValue,
                            clone: true,
                          });
                        },
                        data: data,
                      });
                    }}
                    className="edit menu"
                  >
                    <GetIcon icon={"clone"} />
                    <span>Clone</span>
                  </Button>
                )}
                {actions.map((item, index) => {
                  return (
                    item.element === "button" &&
                    item.type !== "subList" &&
                    item.type !== "subItem" && (
                      <Button
                        theme={themeColors}
                        key={`custom-${item.id + "-" + index}-${data.ID}`}
                        style={{
                          color: item.color,
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          if (item.type === "callback") {
                            item.callback(item, data, refreshUpdate, slNo);
                          } else if (item.type === "call") {
                            window.location.href = `tel:${data.mobileNumber}`;
                          } else if (item.type === "subList" || item.type === "subItem") {
                            setSubAttributes({
                              item,
                              data,
                            });
                            setShowSubList(true);
                          } else {
                            openAction(item, data);
                          }
                        }}
                        className="edit menu"
                      >
                        <GetIcon icon={item.icon} />
                        <span>{item.title}</span>
                      </Button>
                    )
                  );
                })}
                {delPrivilege && !signleRecord && (
                  <Button
                    theme={themeColors}
                    key={`delete-${data.ID}`}
                    onClick={(event) => {
                      event.stopPropagation();
                      setMessage({
                        type: 2,
                        content: `Do you want to delete '${
                          getValue(
                            {
                              type: itemTitle.type ?? "text",
                            },
                            titleValue
                          )
                            ? getValue(
                                {
                                  type: itemTitle.type ?? "text",
                                },
                                titleValue
                              )
                            : "Item"
                        }'?`,
                        proceed: "Delete",
                        onProceed: async () => {
                          await deleteHandler(data, data.ID);
                          return false;
                        },
                        data: data,
                      });
                    }}
                    className="delete menu"
                  >
                    <GetIcon icon={"delete"} />
                    <span>{"Delete"}</span>
                  </Button>
                )}
              </Actions>
            </ToolTip>
          </ToolTipContainer>
        )}
      </React.Fragment>
    );
    let sticky = true;
    switch (viewMode) {
      case "table":
        return (
          <TrView
            onClick={() => {
              if (!signleRecord && enableViewItem === true) {
                setIsOpen(true);
                setOpenData({
                  actions,
                  attributes,
                  data,
                });
                setSubAttributes({
                  actions,
                  attributes,
                  data,
                });
              }
            }}
            style={{
              zIndex: users.data?.response?.length - slNo,
            }}
            key={`${shortName}-${slNo}`}
          >
            {/* <TdView className={sticky} key={-1}>
              {slNo + 1 + currentIndex}
            </TdView> */}
            {attributes.map((attribute, index) => {
              if (attribute.view && (attribute.tag ?? false)) {
                try {
                  let itemValue = attribute.collection?.length > 0 && attribute.showItem?.length > 0 ? data[attribute.collection][attribute.showItem] : data[attribute.name];
                  let imgLink = attribute.imageIcon ? (attribute.collection?.length > 0 && attribute.imageIcon?.length > 0 ? data[attribute.collection][attribute.imageIcon] : data[attribute.imageIcon]) : "";
                  let statusBox = attribute.showStatus ? (attribute.collection?.length > 0 && attribute.showStatus?.length > 0 ? data[attribute.collection][attribute.showStatus] : data[attribute.showStatus]) : "";
                  if (attribute.showSubItem) {
                    itemValue = attribute.collection?.length > 0 && attribute.showItem?.length > 0 ? data[attribute.collection][attribute.showItem][attribute.showSubItem] ?? "" : data[attribute.name];
                  }
                  let dynamicClass = "";
                  if (attribute.condition) {
                    if (data[attribute.condition.item] === attribute.condition.if) {
                      dynamicClass = attribute.condition.then;
                    } else {
                      dynamicClass = attribute.condition.else;
                    }
                  }

                  const result = (
                    <TdView
                      className={`${sticky} ${attribute.wrap ? "wrap" : ""}`}
                      key={index}
                      onClick={() => {
                        if (attribute.editable === true) {
                          alert("yes");
                        } else {
                          // alert("no");
                        }
                      }}
                    >
                      <div>
                        {imgLink?.length > 0 && <img alt="link-icon" src={`${process.env.REACT_APP_CDN}${imgLink}`} />}

                        {attribute.combination ? (
                          <div
                            ref={combinationRef.current[slNo]}
                            onClick={(event) => {
                              event.stopPropagation();
                              setCurrentAction("combination" + data.ID);
                            }}
                          >
                            <Combination closeModal={() => setCurrentAction("")} key={`comb-${data.ID}`} setKey={data.ID} combinationValue={currentAction === "combination" + data.ID ? true : false} setMessage={setMessage} data={data} combinations={data?.Portalschaltungens?.combination}></Combination>
                          </div>
                        ) : dynamicClass === "disabled" ? (
                          "--"
                        ) : (
                          getValue(attribute, itemValue)
                        )}
                        {statusBox.toString()?.length > 0 && <span className={`status-${statusBox.toString()}`}>{t(statusBox ? "online" : "offline")}</span>}
                      </div>
                    </TdView>
                  );
                  sticky = false;
                  return result;
                } catch (error) {
                  const result = <TdView className={sticky} key={index}>{`--`}</TdView>;
                  sticky = false;
                  return result;
                }
              }

              return null;
            })}
            <TdView
              style={{
                zIndex: users.data?.response?.length - slNo,
                border: 0,
              }}
              key={`actions-${shortName}-${data.ID}`}
              className="actions"
            >
              <div className="actions">{ActionDiv}</div>
            </TdView>
          </TrView>
        );
      case "files":
        return (
          <FileItem
            fileSource={data[fileSource] || ""}
            title={titleValue}
            date={data["date"] ?? ""}
            onClick={() => {
              if (!signleRecord && enableViewItem === true) {
                setIsOpen(true);
                setOpenData({
                  actions,
                  attributes,
                  data,
                });
                setSubAttributes({
                  actions,
                  attributes,
                  data,
                });
              }
            }}
            viewMode={viewMode}
            theme={themeColors}
            key={`row-${shortName}-${data.ID ?? slNo}`}
            action={ActionDiv}
          ></FileItem>
        );
      default:
        return (
          <SetTr
            onClick={() => {
              if (!signleRecord && enableViewItem === true) {
                setIsOpen(true);
                setOpenData({
                  actions,
                  attributes,
                  data,
                });
                setSubAttributes({
                  actions,
                  attributes,
                  data,
                });
              }
            }}
            viewMode={viewMode}
            theme={themeColors}
            className={signleRecord ? "single" : ""}
            key={`row-${shortName}-${data.ID ?? slNo}`}
          >
            {profileImage && (
              <ProfileImage>
                <img
                  src={data[profileImage] ? (customProfileSource ? "" : process.env.REACT_APP_CDN) + data[profileImage] : food}
                  onError={(e) => {
                    e.target.src = food; // Hide the image on error
                  }}
                  onClick={(e) => {
                    //image popup
                    e.stopPropagation();
                    setShowImage({
                      src: e.target.src.replace("/thumbnail", ""),
                    });
                  }}
                  alt="Profile"
                ></img>
              </ProfileImage>
            )}
            <ListContainerBox>
              <TrBody className={signleRecord ? "nowrap single" : "nowrap "}>
                <SetTd key={`row-head-${slNo}`}>
                  {signleRecord ? (
                    <Head theme={themeColors} className="single">
                      <GetIcon icon={icon ?? selectedMenuItem.icon} />
                      <span>{shortName}</span>
                    </Head>
                  ) : (
                    <Head
                      onClick={() => {
                        if (enableViewItem === true) {
                          setIsOpen(true);
                          setOpenData({
                            actions,
                            attributes,
                            data,
                          });
                          setSubAttributes({
                            actions,
                            attributes,
                            data,
                          });
                        }
                      }}
                    >
                      {!profileImage && <GetIcon icon={icon ?? selectedMenuItem.icon} />}
                      <span>{` ${getValue(
                        {
                          type: itemTitle.type ?? "text",
                        },
                        titleValue
                      )}`}</span>
                      <Highlight data={data} highlight={highlight}></Highlight>
                    </Head>
                  )}
                </SetTd>
                <Td
                  style={{
                    zIndex: users.data?.response?.length - slNo,
                  }}
                  key={`actions-${shortName}-${data.ID}`}
                  className="actions"
                >
                  {ActionDiv}
                </Td>
              </TrBody>
              {signleRecord ? (
                <DisplayInformations formMode={formMode} attributes={attributes} data={data} />
              ) : (
                <TrBody>
                  {attributes.map((attribute, index) => {
                    if (attribute.view && (attribute.tag ?? false)) {
                      try {
                        let itemValue = attribute.collection?.length > 0 && attribute.showItem?.length > 0 ? data[attribute.collection][attribute.showItem] : data[attribute.name];
                        if (attribute.showSubItem) {
                          itemValue = attribute.collection?.length > 0 && attribute.showItem?.length > 0 ? data[attribute.collection]?.[attribute.showItem]?.[attribute.showSubItem] ?? "" : data?.[attribute.name];
                        }
                        const itemColor = attribute.collection?.length > 0 && attribute.color?.length > 0 ? data[attribute.collection][attribute.color] : "initial";
                        let dynamicClass = "";
                        if (attribute.condition) {
                          if (data[attribute.condition.item] === attribute.condition.if) {
                            dynamicClass = attribute.condition.then;
                          } else {
                            dynamicClass = attribute.condition.else;
                          }
                        }
                        if (attribute.type === "image") {
                          return "";
                        }
                        return (
                          <Td className={"custom " + dynamicClass} key={index}>
                            <Title>{attribute.label}</Title>
                            <DataItem
                              onClick={() => {
                                if (attribute.editable === true) {
                                  const temp = {
                                    ...editable,
                                  };
                                  temp[`${index}-${attribute.name}`] = temp[`${index}-${attribute.name}`] ? !temp[`${index}-${attribute.name}`] : true;
                                  setEditable(temp);
                                }
                              }}
                              style={{
                                color: itemColor,
                              }}
                            >
                              {getValue(attribute, itemValue)}
                            </DataItem>
                            {editable[`${index}-${attribute.name}`] ? <Editable item={attribute} /> : ""}
                          </Td>
                        );
                      } catch (error) {
                        return (
                          <Td key={index}>
                            <Title>{attribute.label}</Title>
                            <DataItem>{`--`} </DataItem>
                          </Td>
                        );
                      }
                    }

                    return null;
                  })}
                </TrBody>
              )}
              <TrBody className="actions">
                {actions.map((item, index) => {
                  let status = true;
                  if (item.condition) {
                    if (data[item.condition.item].toString() === item.condition.if.toString()) {
                      status = item.condition.then;
                    } else {
                      status = item.condition.else;
                    }
                  }

                  return (
                    item.type === "callback" &&
                    status && (
                      <More
                        theme={themeColors}
                        key={`custom-${item.id + "-" + index}-${data.ID}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          item.callback(item, data, refreshUpdate, slNo);
                        }}
                        className="edit menu callBack"
                      >
                        <GetIcon icon={item.icon} />
                        <span>{item.title}</span>
                      </More>
                    )
                  );
                })}
              </TrBody>
            </ListContainerBox>
          </SetTr>
        );
    }
  });
  const closeModal = () => {
    setShowSubList(false);
    setIsOpen(false);
    setIsPrint(false);
    setPrintData([]);
  };
  const [searchValue, setSearchValue] = useState("");
  const mianSearch = (event) => {
    clearTimeout(searchTimeoutRef.current);
    setSearchValue(event.target.value);
    searchTimeoutRef.current = setTimeout(() => {
      setCurrentIndex(0);
      setFilterView({ ...filterView, allSearch: event.target.value });
    }, 400);
  };
  // const [filter, setFilter] = useState(false);
  const searchTimeoutRef = useRef();
  const handleChange = (event, name) => {
    clearTimeout(searchTimeoutRef.current);
    const temp = {
      ...tempTextSearch,
      [name]: event.target.value,
    };
    setTempTextSearch(temp);

    searchTimeoutRef.current = setTimeout(() => {
      setCurrentIndex(0);
      setTextSearch({ ...temp });
    }, 400);
  };
  const selectRef = useRef([]);
  const combinationRef = useRef([]);
  const [currentAction, setCurrentAction] = useState(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const clickedInsideRefs = selectRef.current.filter((ref) => ref.current && ref.current.contains(event.target));
      const clickedCombinationInsideRefs = combinationRef.current.filter((ref) => ref.current && ref.current.contains(event.target));

      if (clickedInsideRefs.length === 0 && clickedCombinationInsideRefs.length === 0) {
        setCurrentAction("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //export to excel
  const toExcel = async (currentIndex) => {
    try {
      await exportToExcel();
    } catch (error) {
      alert(error);
    }
  };
  const printPage = async (currentIndex) => {
    try {
      setLoaderBox(true);
      await getData(
        {
          ...filterView,
          skip: 0,
          limit: 0,
        },
        api,
        dispatch,
        navigate
      )
        .then((response) => {
          setPrintData(response.data);
          setLoaderBox(false);
          setIsPrint(true);
        })
        .catch((error) => {
          setLoaderBox(false);
        });
    } catch (error) {
      alert(error);
    }
  };
  const exportToExcel = async () => {
    setLoaderBox(true);
    await getData(
      {
        ...filterView,
        skip: 0,
        limit: 0,
      },
      api
    )
      .then((response) => {
        const jsonData = response.data.response;
        if (jsonData) {
          const excelData = [];
          jsonData.forEach((data) => {
            const excelRow = {};
            attributes.forEach((attribute) => {
              const name = attribute.label;
              if (attribute.export ?? true) {
                try {
                  let itemValue = attribute.collection?.length > 0 && attribute.showItem?.length > 0 ? data[attribute.collection][attribute.showItem] ?? "" : data[attribute.name];
                  if (attribute.showSubItem) {
                    itemValue = attribute.collection?.length > 0 && attribute.showItem?.length > 0 ? data[attribute.collection][attribute.showItem][attribute.showSubItem] ?? "" : data[attribute.name];
                  }
                  switch (attribute.type) {
                    case "text":
                    case "number":
                    case "password":
                    case "email":
                      return (excelRow[name] = itemValue);
                    case "minute":
                      return (excelRow[name] = convertMinutesToHHMM(parseFloat(itemValue)));
                    case "datetime":
                      return (excelRow[name] = dateTimeFormat(itemValue));
                    case "date":
                      return (excelRow[name] = dateFormat(itemValue));
                    case "checkbox":
                      console.log({
                        itemValue,
                      });
                      return (excelRow[name] = itemValue.toString());
                    case "select":
                      if (attribute.apiType === "JSON") {
                        const fil = attribute.selectApi.filter((item) => item.id.toString() === data[attribute.name]?.toString()).map((filteredItem, index) => filteredItem.value);
                        return (excelRow[name] = fil?.[0]);
                      } else if (attribute.apiType === "CSV") {
                        return (excelRow[name] = itemValue);
                      } else {
                        return (excelRow[name] = itemValue ?? "Nil");
                      }

                    default:
                      switch (typeof itemValue) {
                        case "undefined":
                          return (excelRow[name] = "Not Found");
                        case "object":
                          return (excelRow[name] = itemValue);
                        case "boolean":
                          return (excelRow[name] = itemValue.toString());
                        case "string":
                        case "number":
                        default:
                          if (attribute.type === "select" && attribute.apiType === "JSON") {
                            return attribute.selectApi.filter((item) => item.id.toString() === itemValue?.toString()).map((filteredItem) => (excelRow[name] = filteredItem.value));
                          } else {
                            return (excelRow[name] = itemValue?.toString().substring(0, 200));
                          }
                      }
                  }
                } catch (ee) {
                  excelRow[name] = "--";
                }
              }
            });
            excelData.push(excelRow);
          });
          const worksheet = xlsx.utils.json_to_sheet(excelData);

          // Create workbook
          const workbook = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(workbook, worksheet, shortName);
          // Convert workbook to Excel binary and download the file
          xlsx.writeFile(workbook, shortName + "-data.xlsx");
          setLoaderBox(false);
        }
      })
      .catch((error) => {
        setLoaderBox(false);
      });
  };
  useEffect(() => {
    return () => {
      clearTimeout(searchTimeoutRef.current);
    };
  }, []);
  let headerSticky = true;
  const pageCount = Math.ceil(count / perPage);
  useEffect(() => {
    // if (datefilter) {
    //   setHasFilter(true);
    // }
    formInput.map((item, index) => {
      switch (item.type) {
        case "select":
          if ((item.filter ?? true) === true && (item.filterPlacement ?? "box") === "box") setHasFilter(true);
          return true;
        case "date":
          if ((item.filter ?? false) === true && (item.filterPlacement ?? "box") === "box") setHasFilter(true);
          return true;
        default:
          return true;
      }
    });
  }, [formInput, setHasFilter, datefilter]);
  // const tablerender = useMemo(
  //   () =>
  //     users.data?.response?.length > 0
  //       ? users.data.response.map((item, index) => (
  //           <TableRowWithActions
  //             key={`${shortName}-${index}`}
  //             slNo={index}
  //             attributes={attributes}
  //             data={item}
  //           />
  //         ))
  //       : null,
  //   [users.data?.response, attributes, shortName]
  // );

  //end crud functions
  return isSingle ? (
    users.data?.response?.length > 0 && (
      <RowContainer theme={themeColors} className={"data-layout " + viewMode}>
        <Popup
          isSingle={isSingle}
          parentName={shortName}
          parentIcon={icon}
          popupMode={popupMode}
          showInfo={showInfo}
          popupMenu={popupMenu}
          selectedMenuItem={selectedMenuItem}
          formMode={formMode}
          closeModal={closeModal}
          themeColors={themeColors}
          isEditingHandler={isEditingHandler}
          updateValue={udpateView}
          setMessage={setMessage}
          setLoaderBox={setLoaderBox}
          itemTitle={itemTitle}
          openData={{
            actions,
            attributes,
            data: users.data?.response[0],
          }}
          updatePrivilege={updatePrivilege}
        ></Popup>
      </RowContainer>
    )
  ) : viewMode === "list" || viewMode === "subList" || viewMode === "table" || viewMode === "files" ? (
    <RowContainer theme={themeColors} className={"data-layout " + viewMode}>
      <ButtonPanel className={viewMode} theme={themeColors}>
        <FilterBox>
          {hasFilter && (
            <Filter
              theme={themeColors}
              onClick={() => {
                setShowFilter(!shoFilter);
              }}
            >
              <GetIcon icon={shoFilter ? "close" : ""} />
            </Filter>
          )}
          <Filter
            theme={themeColors}
            onClick={() => {
              // refreshView(currentIndex);
              setReset((re) => re + 1);
              ResetFilter();
            }}
          >
            <GetIcon icon={"reload"} />
          </Filter>
          {(() => {
            const groupedInputs = formInput.reduce(
              (acc, item) => {
                const align = item.filterAlign || "left";
                if (!acc[align]) acc[align] = [];
                acc[align].push(item);
                return acc;
              },
              {
                left: [],
                center: [],
                right: [],
              }
            );

            const renderInputs = (inputs) => {
              return inputs.map((item, index) => {
                let updateValue = {};
                if ((item.type === "select" || item.type === "date" || item.type === "multiSelect") && (item.filter ?? true) === true) {
                  if (Array.isArray(item.updateOn)) {
                    updateValue = {};
                    item.updateOn?.forEach((itemName) => {
                      updateValue[itemName] = filterView[itemName];
                    });
                  } else {
                    updateValue = {
                      [item.updateOn]: filterView[item.updateOn],
                    };
                  }
                }
                switch (item.type) {
                  case "select":
                    return (item.filter ?? true) === true && <FormInput updateValue={updateValue} customClass={`filter header ${item.filterAlign ?? ""}`} placeholder={item.placeHolder} value={filterView[item.name]} key={`input` + index + reset} id={item.name} {...item} onChange={filterChange} required={false} />;
                  case "date":
                    return (item.filter ?? false) === true && <FormInput updateValue={updateValue} customClass={`filter header  ${item.filterAlign ?? ""}`} placeholder={item.placeHolder} value={filterView[item.name]} key={`input` + index + reset} id={item.name} {...item} onChange={filterChange} required={false} />;
                  default:
                    return null;
                }
              });
            };

            return (
              <>
                <FilterBox className="left" key="left-undefined">
                  {renderInputs(groupedInputs.left)}
                </FilterBox>
                {groupedInputs.center.length > 0 && (
                  <FilterBox className="center" key="center">
                    {renderInputs(groupedInputs.center)}
                  </FilterBox>
                )}

                {(groupedInputs.right.length > 0 || datefilter) && (
                  <FilterBox className="right" key="right">
                    {datefilter && <DateRangeSelector datefilterType={datefilterType} onChange={dateRangeChange} onFilterChange={filterChange} themeColors={themeColors}></DateRangeSelector>}
                    {renderInputs(groupedInputs.right)}
                  </FilterBox>
                )}
              </>
            );
          })()}

          {exportPrivilege && (
            <Filter
              theme={themeColors}
              onClick={(event) => {
                event.stopPropagation();
                setMessage({
                  type: 2,
                  content: "Do you want export this page to excel?",
                  proceed: "Export Now",
                  onProceed: async () => {
                    await toExcel();
                    return true;
                  },
                  data: currentIndex,
                });
              }}
            >
              <GetIcon icon={"excel"} />
            </Filter>
          )}
          {printPrivilege && (
            <Filter
              theme={themeColors}
              onClick={(event) => {
                event.stopPropagation();
                setMessage({
                  type: 2,
                  content: "Do you want print?",
                  proceed: "Print Now",
                  onProceed: async () => {
                    await printPage();
                    return false;
                  },
                  data: currentIndex,
                });
              }}
            >
              <GetIcon icon={"print"} />
            </Filter>
          )}
          {searchPrivilege && <SearchMain title={"Search"} theme={themeColors} placeholder="Search" value={searchValue} onChange={mianSearch}></SearchMain>}
        </FilterBox>
        {(addPrivilege ? addPrivilege : false) && (
          <AddButton theme={themeColors} onClick={() => isCreatingHandler(true, refreshView)}>
            <AddIcon></AddIcon>
            <span>{shortName}</span>
          </AddButton>
        )}
      </ButtonPanel>
      <ListContainer className={`${shoFilter && "show-filter"} ${popupMenu} ${popupMode} `}>
        <Filters>
          {datefilter && <DateRangeSelector onChange={dateRangeChange} themeColors={themeColors}></DateRangeSelector>}
          {formInput.map((item, index) => {
            let updateValue = {};
            if ((item.type === "select" || item.type === "multiSelect" || item.type === "date") && (item.filter ?? true) === true) {
              if (Array.isArray(item.updateOn)) {
                updateValue = {};
                item.updateOn?.forEach((itemName) => {
                  updateValue[itemName] = filterView[itemName];
                });
              } else {
                updateValue = {
                  [item.updateOn]: filterView[item.updateOn],
                };
              }
            }
            switch (item.type) {
              case "select":
                return (item.filter ?? true) === true && <FormInput updateValue={updateValue} customClass={"filter"} placeholder={item.placeHolder} value={filterView[item.name]} key={`input` + index + reset} id={item.name} {...item} onChange={filterChange} required={false} />;
              case "date":
                return (item.filter ?? false) === true && <FormInput updateValue={updateValue} customClass={"filter"} placeholder={item.placeHolder} value={filterView[item.name]} key={`input` + index + reset} id={item.name} {...item} onChange={filterChange} required={false} />;
              default:
                return null;
            }
          })}
          <EButton
            align="margin-top"
            ClickEvent={() => {
              setReset((re) => re + 1);
              setFilterView(
                referenceId !== 0
                  ? {
                      [parentReference]: referenceId,
                      ...preFilter,
                    }
                  : {
                      ...preFilter,
                    }
              );
            }}
            type="secondary"
            value="Clear Filter"
            icon="delete"
          ></EButton>
        </Filters>
        <ListContainerData>
          {viewMode === "table" ? (
            <TableContaner className={isPopup ? "popup" : ""}>
              <TableView theme={themeColors}>
                <thead>
                  <tr>
                    {attributes.map((attribute) => {
                      const result =
                        attribute.view && (attribute.tag ?? false) ? (
                          <ThView className={headerSticky} key={shortName + attribute.name}>
                            <div>
                              {(attribute.sort ?? false) && (
                                <button
                                  onClick={() => {
                                    setSortView((prev) => ({
                                      ...prev,
                                      [attribute.name]: prev[attribute.name] === "asc" ? "desc" : prev[attribute.name] === "desc" ? "" : "asc",
                                    }));
                                  }}
                                  className={`sort ${sortView?.[attribute.name] ?? ""}`}
                                >
                                  <GetIcon icon="sort" />
                                </button>
                              )}
                              {t(attribute.label)}
                            </div>
                          </ThView>
                        ) : null;
                      headerSticky = false;
                      return result;
                    })}
                  </tr>
                  {attributes.some((attr) => attr.view && (attr.tag ?? false) && attr.type === "text" && (attr.filter ?? false)) && (
                    <tr>
                      {attributes.map((attribute) => {
                        if (attribute.view && (attribute.tag ?? false)) {
                          return attribute.type === "text" && (attribute.filter ?? false) ? (
                            <ThView className="sub" key={`filter-${attribute.name}`}>
                              <div>
                                <Search active={true} title={`Search`} theme={themeColors} placeholder={`Search ${t(attribute.label)}`} value={tempTextSearch?.[attribute.searchKey] ?? ""} onChange={(event) => handleChange(event, attribute.searchKey)} />
                              </div>
                            </ThView>
                          ) : (
                            <ThView className="sub" key={`filter-${attribute.name}`}></ThView>
                          );
                        }
                        return null;
                      })}
                      <ThView key="actions" />
                    </tr>
                  )}
                </thead>

                <tbody>{users.data?.response?.length > 0 ? users.data.response.map((item, index) => <TableRowWithActions key={`${shortName}-${index}`} slNo={index} attributes={attributes} data={item} />) : null}</tbody>
              </TableView>
              {!users.data && !users.data?.response && <NoData className="noshadow">No {shortName} found!</NoData>}
              {users.data?.response?.length === 0 && <NoData className="noshadow">No records found for {shortName}.</NoData>}
            </TableContaner>
          ) : (
            <>
              <Table className={`${viewMode} ${displayColumn} ${count > 0 ? "" : "no-data"}`}>
                {users.data?.response?.length > 0 && users.data.response.map((item, index) => <TableRowWithActions key={`${shortName}-${index}`} slNo={index} attributes={attributes} data={item} />)}
                {!users.data && !users.data?.response && <NoData className="white-list">No {shortName} found!</NoData>}
                {users.data?.response?.length === 0 && <NoData className="white-list">No records found for {shortName}.</NoData>}
              </Table>
            </>
          )}

          {isCreating && <CrudForm parentReference={parentReference} referenceId={referenceId} formMode={formMode} api={api} formType={"post"} header={`Add a ${shortName ? shortName : "Form"}`} formInput={formInput} formValues={addValues} formErrors={errroInput} submitHandler={submitHandler} isOpenHandler={isCreatingHandler} isOpen={isCreating}></CrudForm>}

          {action.data && <Manage setMessage={setMessage} setLoaderBox={setLoaderBox} onClose={closeManage} {...action}></Manage>}

          {isOpen && <Popup parentName={shortName} parentIcon={icon} popupMode={popupMode} showInfo={showInfo} popupMenu={popupMenu} selectedMenuItem={selectedMenuItem} formMode={formMode} closeModal={closeModal} themeColors={themeColors} isEditingHandler={isEditingHandler} updateValue={udpateView} setMessage={setMessage} setLoaderBox={setLoaderBox} itemTitle={itemTitle} openData={openData} updatePrivilege={updatePrivilege}></Popup>}
          {isEditing && <CrudForm parentReference={parentReference} referenceId={referenceId} formMode={formMode} api={api} formType={"put"} updateId={updateId} header={`${updateValues.clone === false ? `Update ${shortName}: ` : `Clone ${shortName}: `}  <span style="font-weight:bold">'${updateValues._title}'</span>`} formInput={formInput} formErrors={errroInput} formValues={updateValues} submitHandler={updateHandler} isOpenHandler={isEditingHandler} isOpen={isEditing}></CrudForm>}
          {detailView && <Details formMode={formMode} closeModal={closeModal} themeColors={themeColors} setMessage={setMessage} setLoaderBox={setLoaderBox} itemTitle={itemTitle} openData={openData}></Details>}
          {showSublist && subAttributes?.item?.attributes?.length > 0 && <SubPage themeColors={themeColors} formMode={formMode} closeModal={closeModal} setMessage={setMessage} setLoaderBox={setLoaderBox} itemTitle={itemTitle} subAttributes={subAttributes}></SubPage>}
          {isPrint && (
            <PopupView
              customClass={"print"}
              popupData={<Print orientation={orientation} key={shortName} data={printData} themeColors={themeColors} formMode={formMode} closeModal={() => setIsPrint(false)} setMessage={setMessage} setLoaderBox={setLoaderBox} shortName={shortName} attributes={attributes}></Print>}
              themeColors={themeColors}
              closeModal={() => setIsPrint(false)}
              itemTitle={{
                name: "title",
                type: "text",
                collection: "",
              }}
              openData={{
                data: {
                  key: "print_preparation",
                  title: "Print " + shortName,
                },
              }}
            ></PopupView>
          )}
          {showImage && <ImagePopup onClose={() => setShowImage(null)} src={showImage.src}></ImagePopup>}
          {showPageCount && (
            <PopupView
              // Popup data is a JSX element which is binding to the Popup Data Area like HOC
              popupData={
                <>
                  <TabContainer className="page">
                    <div className="head">Items Per Page</div>
                    {[10, 25, 50, 100, 250].map((num) => (
                      <PageNumber
                        theme={themeColors}
                        key={`per-${num}`}
                        className={"nomargin " + (perPage === num)}
                        onClick={() => {
                          setPerPage(num);
                        }}
                      >
                        {num}
                      </PageNumber>
                    ))}
                  </TabContainer>
                  <TabContainer className="page">
                    <div className="head">
                      Pages: {pageCount} | Current Page: {pageNumber}
                    </div>
                    {Array.from(
                      {
                        length: pageCount,
                      },
                      (_, index) => index + 1
                    ).map((num) => (
                      <PageNumber
                        key={`page-${num}`}
                        className={"nomargin " + (pageNumber === num)}
                        onClick={() => {
                          setCurrentIndex((num - 1) * perPage);
                        }}
                      >
                        {num}
                      </PageNumber>
                    ))}
                  </TabContainer>
                </>
              }
              themeColors={themeColors}
              closeModal={() => setShowPageCount(false)}
              itemTitle={{
                name: "title",
                type: "text",
                collection: "",
              }}
              openData={{
                data: {
                  _id: "",
                  title: "Pagination Setup!",
                },
              }} // Pass selected item data to the popup for setting the time and taking menu id and other required data from the list item
              customClass={"small"}
            ></PopupView>
          )}
          {sessionId && <UploadStatusDisplay sessionId={sessionId} />}
          {showLoader && <Loader className={isPopup ? "" : "absolute"}></Loader>}
        </ListContainerData>
      </ListContainer>
      {count > 0 ? (
        count > perPage ? (
          <Count>
            <ArrowPagination
              theme={themeColors}
              onClick={() => {
                setCurrentIndex((prev) => (prev > 9 ? prev - perPage : 0));
              }}
            >
              <GetIcon icon={"previous"} />
            </ArrowPagination>
            {isMobile ? `Showing ${currentIndex + 1} - ${currentIndex + perPage > count ? count : currentIndex + perPage} out of ${count}` : `Showing ${currentIndex + 1} - ${currentIndex + perPage > count ? count : currentIndex + perPage} out of ${count} records`}
            <ArrowPagination
              theme={themeColors}
              onClick={() => {
                setCurrentIndex((prev) => (prev + perPage > count ? currentIndex : currentIndex + perPage));
              }}
            >
              <GetIcon icon={"next"} />
            </ArrowPagination>
            <ArrowPagination
              className="button"
              onClick={() => {
                setShowPageCount(true);
              }}
            >
              {`${perPage} per Page`}
              <GetIcon icon={"settings"}></GetIcon>
            </ArrowPagination>
          </Count>
        ) : (
          <Count>{isMobile ? `${count} record${count > 1 ? "s" : ""}` : `Showing ${count} record${count > 1 ? "s" : ""}`}</Count>
        )
      ) : (
        <Count>{`No records found`}</Count>
      )}
    </RowContainer>
  ) : (
    <RowContainer className={"data-layout "}>
      {users.data?.response?.length === 0 && (
        <ButtonPanel>
          <FilterBox></FilterBox>
          {(addPrivilege ? addPrivilege : false) && users.data?.response?.length === 0 && (
            <AddButton theme={themeColors} onClick={() => isCreatingHandler(true, refreshView)}>
              <AddIcon></AddIcon>
              {shortName}
            </AddButton>
          )}
        </ButtonPanel>
      )}
      <Table className={users.data?.response?.length === 0 ? "norecord" : "record"}>{users.data?.response?.length > 0 && <TableRowWithActions key={`${shortName}-${0}`} slNo={0} attributes={attributes} data={users.data?.response[0]} />}</Table>
      {!users.data && !users.data?.response && <NoData className="white-list">No {shortName} found!</NoData>}
      {users.data?.response?.length === 0 && <NoData className="white-list">No data found for {shortName}.</NoData>}
      {isCreating && <CrudForm parentReference={parentReference} referenceId={referenceId} api={api} formMode={formMode} formType={"post"} header={`Add a ${shortName ? shortName : "Form"}`} formInput={formInput} formValues={addValues} formErrors={errroInput} submitHandler={submitHandler} isOpenHandler={isCreatingHandler} isOpen={isCreating}></CrudForm>}
      {isEditing && <CrudForm parentReference={parentReference} referenceId={referenceId} formMode={formMode} api={api} formType={"put"} updateId={updateId} header={`${updateValues.clone === false ? `Update ${shortName}: ` : `Clone ${shortName}: `} <span style="font-weight:bold">'${updateValues._title}' </span>`} formInput={formInput} formErrors={errroInput} formValues={updateValues} submitHandler={updateHandler} isOpenHandler={isEditingHandler} isOpen={isEditing}></CrudForm>}
      {action.data && <Manage setMessage={setMessage} setLoaderBox={setLoaderBox} onClose={closeManage} {...action}></Manage>}
      {isOpen && <Popup popupMenu={popupMenu} data={openData} actions={actions}></Popup>}
      {showLoader && <Loader className={isPopup ? "" : "absolute"}></Loader>}
    </RowContainer>
  );
};
export default ListTable;
