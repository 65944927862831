// import React, { useState, useCallback, useEffect } from "react";
// import { useDropzone } from "react-dropzone";
// import Cropper from "react-easy-crop";
// import { getCroppedImg } from "../utils/cropImage";
// import styled from "styled-components";
// import { getData, postData } from "../../../backend/api";
// import { IconButton } from "../elements";

// const ImageUploaderContainer = styled.div`
//   padding: 30px;
// `;

// const DropzoneContainer = styled.div`
//   border: 2px dashed #d1d5db;
//   margin-bottom: 2rem;
//   padding: 2rem;
//   text-align: center;
//   cursor: pointer;
//   ${({ isDragActive }) =>
//     isDragActive
//       ? `
//         border-color: #3b82f6;
//         background-color: #e0e7ff;
//       `
//       : ""}
// `;

// const CropperContainer = styled.div`
//   position: relative;
//   height: 24rem;
// `;

// const AspectRatioContainer = styled.div`
//   margin-top: 1rem;
// `;

// const CropAndUploadButton = styled.button`
//   background-color: #3b82f6;
//   color: white;
//   padding: 0.5rem 1rem;
//   border-radius: 0.375rem;
//   &:hover {
//     background-color: #2563eb;
//   }
// `;

// const Select = styled.select`
//   margin-bottom: 1rem;
//   padding: 0.5rem;
//   border: 1px solid #d1d5db;
//   border-radius: 0.375rem;
// `;

// const ImageGrid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
//   grid-gap: 1rem;
// `;

// const ImageWrapper = styled.div`
//   position: relative;
//   cursor: pointer;
//   border: 0px solid rgb(233, 233, 233);
//   box-shadow: rgba(0, 0, 0, 0) 0px 0px, rgba(0, 0, 0, 0) 0px 0px, rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
//   padding: 10px;
//   height: 120px;
//   border-radius: 12px;
//   img {
//     width: 100%;
//     min-height: 80px;
//     max-height: 75%;
//     object-fit: contain;
//     border-radius: 12px;
//   }
// `;

// const Buttons = styled.div`
//   display: flex;
//   gap: 10px;
// `;

// const ImageUploader = ({ onSelect }) => {
//   const [image, setImage] = useState(null);
//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [zoom, setZoom] = useState(1);
//   const [aspect, setAspect] = useState(4 / 3);
//   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
//   const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
//   const [uploadedImages, setUploadedImages] = useState([]);
//   const [isImageLoaded, setIsImageLoaded] = useState(false);
//   const [aspectRatioOptions, setAspectRatioOptions] = useState([
//     { value: 1, label: "1:1 (Quadratisch)" },
//     { value: 4 / 3, label: "4:3 (Standardformat)" },
//     { value: 16 / 9, label: "16:9 (Breitbild)" },
//     { value: 5 / 2, label: "5:2 (Landschaftsformat)" },
//     { value: 3 / 2, label: "3:2 (Leicht breiter als hoch)" },
//     { value: 2 / 1, label: "2:1 (Panoramaformat)" },
//     { value: 9 / 16, label: "9:16 (Hochformat)" },
//     { value: 2 / 3, label: "2:3 (Portrait)" },
//     { value: 4 / 5, label: "4:5 (Portrait)" },
//     { value: 3 / 1, label: "3:1 (Wide Banner)" },
//     { value: 5 / 4, label: "5:4 (Leicht quadratisch)" },
//     { value: 21 / 9, label: "21:9 (Ultra-Widescreen)" },
//     { value: 16 / 10, label: "16:10 (Widescreen)" },
//   ]);
//   const onDrop = useCallback((acceptedFiles) => {
//     const file = acceptedFiles[0];
//     setImage(URL.createObjectURL(file));

//     // Get the image size
//     const img = new Image();
//     img.src = URL.createObjectURL(file);
//     img.onload = () => {
//       setImageSize({ width: img.width, height: img.height });
//     };
//   }, []);

//   const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { "image/*": [] } });

//   const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
//     setCroppedAreaPixels(croppedAreaPixels);
//   }, []);

//   const handleCrop = async () => {
//     if (image && croppedAreaPixels) {
//       const croppedImage = await getCroppedImg(image, croppedAreaPixels);
//       await uploadImage(croppedImage);
//       setImage(null);
//       fetchImages(); // Fetch updated images after upload
//     }
//   };

//   useEffect(() => {
//     if (imageSize.width > 0 && imageSize.height > 0) {
//       // Calculate the aspect ratio based on the image size
//       const aspectRatio = imageSize.width / imageSize.height;
//       setAspect(aspectRatio);
//     }
//   }, [imageSize]);

//   // Fetch images from the server
//   // Fetch images from the server
//   const fetchImages = async () => {
//     try {
//       const response = await getData({}, "media-gallery");
//       const images = await response.data.response;
//       setUploadedImages(images);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   // Upload image to the server
//   const uploadImage = async (File) => {
//     console.log(File);
//     const response = await postData({ File: [File] }, "media-gallery");
//     if (response.status === 200) {
//       return null;
//     }
//     return null;
//   };

//   useEffect(() => {
//     if (!isImageLoaded) {
//       setIsImageLoaded(true);
//       fetchImages(); // Fetch images when the component mounts
//     }
//   }, [isImageLoaded]);

//   const startCropping = (image) => {
//     setImage(image.URL);
//   };
//   useEffect(() => {
//     if (imageSize.width > 0 && imageSize.height > 0) {
//       const aspectRatio = imageSize.width / imageSize.height;
//       const defaultOption = aspectRatioOptions.find((option) => option.value === aspectRatio);
//       if (defaultOption) {
//         setAspect(defaultOption.value);
//       } else {
//         setAspect(aspectRatioOptions[1].value); // Set 4:3 as the default if not found
//       }
//     }
//   }, [imageSize, aspectRatioOptions]);
//   return (
//     <ImageUploaderContainer>
//       {!image && (
//         <DropzoneContainer {...getRootProps()} isDragActive={isDragActive}>
//           <input {...getInputProps()} />
//           <p>Drag & drop an image here, or click to select one</p>
//         </DropzoneContainer>
//       )}
//       {image && typeof image === "string" && (
//         <CropperContainer>
//           <Cropper image={image} crop={crop} zoom={zoom} aspect={aspect} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete} />
//         </CropperContainer>
//       )}
//       {image && typeof image === "string" && (
//         <AspectRatioContainer>
//           <label>Aspect Ratio: {aspect}</label>
//           <Select value={aspect} onChange={(e) => setAspect(parseFloat(e.target.value))}>
//             {aspectRatioOptions.map((option) => (
//               <option key={option.value} value={option.value}>
//                 {option.label}
//               </option>
//             ))}
//           </Select>
//           <CropAndUploadButton onClick={handleCrop}>Crop and Upload</CropAndUploadButton>
//         </AspectRatioContainer>
//       )}
//       <ImageGrid>
//         {uploadedImages.map((image) => (
//           <ImageWrapper key={image.ID}>
//             <img src={image.URL} alt="Uploaded" />
//             <Buttons>
//               <IconButton align="small" icon={"checked"} ClickEvent={() => onSelect(`${image.URL}?id=${image.ID}`)}></IconButton>
//               <IconButton align="small" icon={"crop"} ClickEvent={() => startCropping(image)}></IconButton>
//               <IconButton align="small delete" icon={"delete"} ClickEvent={() => {}}></IconButton>
//             </Buttons>
//           </ImageWrapper>
//         ))}
//       </ImageGrid>
//     </ImageUploaderContainer>
//   );
// };

// export default ImageUploader;
import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "../utils/cropImage";
import styled from "styled-components";
import { getData, postData } from "../../../backend/api";
import { Button, IconButton, Select } from "../elements";

const ImageUploaderContainer = styled.div`
  padding: 0 30px 30px;
`;

const DropzoneContainer = styled.div`
  border: 2px dashed #d1d5db;
  margin-bottom: 2rem;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  ${({ isDragActive }) =>
    isDragActive
      ? `  
        border-color: #3b82f6;  
        background-color: #e0e7ff;  
      `
      : ""}
`;

const CropperContainer = styled.div`
  position: relative;
  height: 18rem;
`;

const AspectRatioContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1rem;
`;

// const Select = styled.select`
//   margin-bottom: 1rem;
//   padding: 0.5rem;
//   border: 1px solid #d1d5db;
//   border-radius: 0.375rem;
// `;

const ImageGrid = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 1rem;
`;

const ImageWrapper = styled.div`
  position: relative;
  cursor: pointer;
  border: 0px solid rgb(233, 233, 233);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px, rgba(0, 0, 0, 0) 0px 0px, rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  padding: 10px;
  height: 120px;
  border-radius: 12px;
  img {
    width: 100%;
    min-height: 80px;
    max-height: 75%;
    object-fit: contain;
    border-radius: 12px;
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;
`;

const ImageUploader = ({ onSelect, setLoaderBox }) => {
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState(4 / 3);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [aspectRatioOptions, setAspectRatioOptions] = useState([
    { id: 1, value: "From Image" },
    { id: 1, value: "1:1 (Quadratisch)" },
    { id: 4 / 3, value: "4:3 (Standardformat)" },
    { id: 16 / 9, value: "16:9 (Breitbild)" },
    { id: 5 / 2, value: "5:2 (Landschaftsformat)" },
    { id: 3 / 2, value: "3:2 (Leicht breiter als hoch)" },
    { id: 2 / 1, value: "2:1 (Panoramaformat)" },
    { id: 9 / 16, value: "9:16 (Hochformat)" },
    { id: 2 / 3, value: "2:3 (Portrait)" },
    { id: 4 / 5, value: "4:5 (Portrait)" },
    { id: 3 / 1, value: "3:1 (Wide Banner)" },
    { id: 5 / 4, value: "5:4 (Leicht quadratisch)" },
    { id: 21 / 9, value: "21:9 (Ultra-Widescreen)" },
    { id: 16 / 10, value: "16:10 (Widescreen)" },
  ]);
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setImage(URL.createObjectURL(file));
    // Get the image size
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      setImageSize({ width: img.width, height: img.height });
    };
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { "image/*": [] } });

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    if (image && croppedAreaPixels) {
      setLoaderBox(true);
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      await uploadImage(croppedImage);
      setImage(null);
      fetchImages(); // Fetch updated images after upload
    }
  };

  useEffect(() => {
    if (imageSize.width > 0 && imageSize.height > 0) {
      // Calculate the aspect ratio based on the image size
      const aspectRatio = imageSize.width / imageSize.height;
      setAspectRatioOptions((prev) => {
        prev[0] = { id: aspectRatio, value: "Default Ratio" };
        return prev;
      });
      setAspect(aspectRatio);
    }
  }, [imageSize]);

  // Fetch images from the server
  // Fetch images from the server
  const fetchImages = useCallback(async () => {
    try {
      setLoaderBox(true);
      const response = await getData({}, "media-gallery");
      const images = await response.data.response;
      setUploadedImages(images);
      setLoaderBox(false);
    } catch (error) {
      setLoaderBox(false);
      console.error(error);
    }
  }, [setLoaderBox, setUploadedImages]);

  // Upload image to the server
  const uploadImage = async (File) => {
    console.log(File);
    const response = await postData({ File: [File] }, "media-gallery");
    if (response.status === 200) {
      return null;
    }
    return null;
  };

  useEffect(() => {
    if (!isImageLoaded) {
      setIsImageLoaded(true);
      fetchImages(); // Fetch images when the component mounts
    }
  }, [isImageLoaded, fetchImages]);

  const startCropping = (image) => {
    setImage(image.URL);
  };
  useEffect(() => {
    if (imageSize.width > 0 && imageSize.height > 0) {
      const aspectRatio = imageSize.width / imageSize.height;
      const defaultOption = aspectRatioOptions.find((option) => option.id === aspectRatio);
      if (defaultOption) {
        setAspect(defaultOption.id);
      } else {
        setAspect(aspectRatioOptions[1].id); // Set 4:3 as the default if not found
      }
    }
  }, [imageSize, aspectRatioOptions]);
  return (
    <ImageUploaderContainer>
      {!image && (
        <DropzoneContainer {...getRootProps()} isDragActive={isDragActive}>
          <input {...getInputProps()} />
          <p>Drag & drop an image here, or click to select one</p>
        </DropzoneContainer>
      )}
      {image && typeof image === "string" && (
        <CropperContainer>
          <Cropper image={image} crop={crop} zoom={zoom} aspect={aspect} onCropChange={setCrop} onZoomChange={setZoom} onCropComplete={onCropComplete} />
        </CropperContainer>
      )}
      {image && typeof image === "string" && (
        <AspectRatioContainer>
          <Select align="filter header " showLabel={false} label={"Aspect Ratio:"} apiType="JSON" selectApi={aspectRatioOptions} value={aspect} onSelect={(item) => setAspect(item.id)}></Select>
          <Button ClickEvent={handleCrop} value="Crop and Upload" />
        </AspectRatioContainer>
      )}
      <ImageGrid>
        {uploadedImages.map((image) => (
          <ImageWrapper key={image.ID}>
            <img src={image.URL} alt="Uploaded" />
            <Buttons>
              <IconButton align="small" icon={"checked"} ClickEvent={() => onSelect(`${image.URL}?id=${image.ID}`)}></IconButton>
              <IconButton align="small" icon={"crop"} ClickEvent={() => startCropping(image)}></IconButton>
              <IconButton align="small delete" icon={"delete"} ClickEvent={() => {}}></IconButton>
            </Buttons>
          </ImageWrapper>
        ))}
      </ImageGrid>
    </ImageUploaderContainer>
  );
};

export default ImageUploader;
