import React, { useState, useEffect, useCallback } from "react";
import parse from "html-react-parser";
import { FragDiv } from "./styles";

const DynamicTemplate = ({ item, onChange, fieldData, onChildChange, childData, addNewChild, setLoaderBox }) => {
  const [fields, setFields] = useState(item.fields);
  const [fieldDataTemp, setfieldData] = useState(fieldData);
  const [childDataTemp, setChildData] = useState(childData ?? {});
  const [processedHtml, setProcessedHtml] = useState(parse(item.htmlTemplates));

  // Update fields when item changes
  useEffect(() => {
    setFields(item.fields);
  }, [item]);

  useEffect(() => {
    setfieldData(fieldData);
  }, [fieldData]);

  useEffect(() => {
    setChildData(childData ?? {});
  }, [childData]);

  const replacePlaceholders = useCallback(
    (template, data) => {
      // Replace placeholders with input fields
      const htmlWithInputs = template.replace(/\{(.*?)\}/g, (match, key) => {
        const field = data[key];
        if (key.startsWith("child_")) {
          const childTemplate = item.childTemplate;
          if (childTemplate) {
            let childrenHtml = "";
            const childCount = childDataTemp[key] ?? [];
            for (let i = 0; i < childCount.length; i++) {
              childrenHtml += childTemplate.htmlTemplates.replace(/\{(.*?)\}/g, (childMatch, childKey) => {
                if (childKey.startsWith("parent_")) {
                  const parentKey = childKey.replace("parent_", "");
                  const parentField = fields[parentKey];
                  if (parentField) {
                    return fieldDataTemp?.[parentKey] ? fieldDataTemp[parentKey] : parentField.sample;
                  }
                  return childMatch;
                } else {
                  const childField = childTemplate.fields[childKey];
                  if (childField) {
                    return childField.type === "image" ? childDataTemp[key]?.[i]?.[childKey] || childField.sample : `<span contenteditable="true"  style="cursor:text" suppressContentEditableWarning={true} key="${childKey}-${i}" data-type="child" data-main-key="${key}" data-index="${i}" data-key="${childKey}">${childDataTemp[key]?.[i]?.[childKey] || childField.sample}</span>`;
                  }
                  return childMatch;
                }
              });
            }
            // Add "Add New" button
            childrenHtml += `<button className="add-new"  data-button-type="add-child" data-button-key="${key}">Add New</button>`;
            return childrenHtml;
          }
        }
        if (field) {
          return field.type === "image" ? (fieldDataTemp?.[key] ? fieldDataTemp[key] : field.sample) : `<span contenteditable="true" style="cursor: text" suppressContentEditableWarning={true} key="${key}" data-key="${key}">${fieldDataTemp?.[key] ? fieldDataTemp[key] : field.sample}</span>`;
        }
        return match; // Return the original match if no field is found
      });
      return parse(htmlWithInputs); // Parse the modified HTML string into React elements
    },
    [fieldDataTemp, childDataTemp, item.childTemplate, fields]
  ); // Add fieldDataTemp as a dependency

  // Update processed HTML when item or fieldDataTemp changes
  useEffect(() => {
    const newHtml = replacePlaceholders(item.htmlTemplates, fields);
    setProcessedHtml(newHtml);
  }, [item.htmlTemplates, fields, fieldData, replacePlaceholders]);

  // Handle input change events
  const handleInputChange = (e) => {
    const type = e.target.getAttribute("data-type");
    const key = e.target.getAttribute("data-key");
    const value = e.target.innerText; // Get the content of the span
    console.log(type, key, value);
    if (type === "child") {
      const index = e.target.getAttribute("data-index");
      const parent = e.target.getAttribute("data-main-key");

      onChildChange(index, parent, key, value);
    } else {
      onChange(key, value);
    }
  };
  const handleButtonClick = (e) => {
    const buttonType = e.target.getAttribute("data-button-type");
    const buttonKey = e.target.getAttribute("data-button-key");
    if (buttonType === "add-child") {
      addNewChild(buttonKey);
    }
  };
  return (
    <FragDiv>
      {/* {JSON.stringify(item.htmlTemplates)} */}
      {React.Children.map(processedHtml, (child, index) => {
        if (React.isValidElement(child)) {
          // Add onInput and onBlur events to the child
          return React.cloneElement(child, {
            onInput: handleInputChange,
            onClick: handleButtonClick,
          });
        }
        return child;
      })}
    </FragDiv>
  );
};

export default DynamicTemplate;
