import React, { useState } from "react";
import styled from "styled-components";
import ImageSelector from "../imageselector";
import PopupView from "../popupview";
import { ElementContainer, IconButtonTransparent, TextArea, TextBox } from "../elements";
import { InputContainer, Label } from "../input/styles";
import { Accordian, AccordianContent, AccordianTitle } from "./styles";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledImage = styled.img`
  width: 100%; /* Ensure the image does not exceed 100% width */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover;
`;

const DynamicSidebar = ({ item, onChange, fieldData, childData, t, setLoaderBox }) => {
  const [fields] = useState(item.fields);
  const [isImageOpen, setIsImageOpen] = useState(null); // Store selected images by key
  const renderInputField = () => {
    const items = Object.keys(fields).map((key) => {
      const field = fields[key]; // Get the field object using the key
      if (key.startsWith("child_")) {
        return <div>{key}</div>;
      } else {
        switch (field.type) {
          case "text":
            return <TextBox name={key} key={key} value={fieldData?.[key] ? fieldData[key] : ""} placeholder={field.label} label={field.label} onChange={(value) => onChange(key, value)} />;
          case "image":
            return (
              <InputContainer key={key} className="box">
                <Label className="stick">{`${t(field.label)}`}</Label>
                <StyledImage src={fieldData?.[key] ? fieldData[key] : field.sample} alt="Dynamic" />
                <IconButtonTransparent align="edit" icon="edit" ClickEvent={() => setIsImageOpen(key)}></IconButtonTransparent>
              </InputContainer>
            );
          case "textarea":
            return <TextArea name={key} key={key} value={fieldData?.[key] ? fieldData[key] : ""} placeholder={field.label} label={field.label} onChange={(value) => onChange(key, value)} />;
          default:
            return <TextArea name={key} key={key} value={fieldData?.[key] ? fieldData[key] : ""} placeholder={field.label} label={field.label} onChange={(value) => onChange(key, value)} />;
        }
      }
    });
    return items ? items : "No Settings found!";
  };

  const renderChildFields = () => {
    const items = Object.keys(childData ?? {}).map((key) => {
      const childFields = item?.childTemplate?.fields;
      const childDataNew = childData[key];
      return childDataNew.map((child, index) => (
        <Accordian key={key}>
          <AccordianTitle>Item {index + 1}</AccordianTitle>
          <AccordianContent>
            {Object.keys(childFields).map((fieldKey) => {
              const field = childFields[fieldKey];
              switch (field.type) {
                case "text":
                  return <TextBox name={fieldKey} key={fieldKey} value={child[fieldKey] ?? ""} placeholder={field.label} label={field.label} onChange={(value) => onChange(fieldKey, value)} />;
                case "image":
                  return (
                    <InputContainer key={fieldKey} className="box">
                      <Label className="stick">{`${t(field.label)}`}</Label>
                      <StyledImage src={child[fieldKey] ?? field.sample} alt="Dynamic" />
                      <IconButtonTransparent align="edit" icon="edit" ClickEvent={() => setIsImageOpen(fieldKey)} />
                    </InputContainer>
                  );
                case "textarea":
                  return <TextArea name={fieldKey} key={fieldKey} value={child[fieldKey] ?? ""} placeholder={field.label} label={field.label} onChange={(value) => onChange(fieldKey, value)} />;
                default:
                  return <TextArea name={fieldKey} key={fieldKey} value={child[fieldKey] ?? ""} placeholder={field.label} label={field.label} onChange={(value) => onChange(fieldKey, value)} />;
              }
            })}
          </AccordianContent>
        </Accordian>
      ));
    });
    return items.length > 0 ? items : "";
  };

  return (
    fields && (
      <FormContainer>
        {/* {JSON.stringify(childData)} */}
        {Object.keys(fields)?.length > 0 && <ElementContainer className="column">{renderInputField()}</ElementContainer>}
        {Object.keys(childData ?? {})?.length > 0 && <ElementContainer className="column">{renderChildFields()}</ElementContainer>}
        {isImageOpen && (
          <PopupView
            // Popup data is a JSX element which is binding to the Popup Data Area like HOC
            popupData={
              <ImageSelector
                setLoaderBox={setLoaderBox}
                onSelect={(dataUrl) => {
                  onChange(isImageOpen, dataUrl);
                  setIsImageOpen(null);
                }}
              ></ImageSelector>
            }
            closeModal={() => {
              setIsImageOpen(null);
            }}
            itemTitle={{
              name: "title",
              type: "text",
              collection: "",
            }}
            openData={{
              data: {
                _id: "",
                title: "Media Gallery",
              },
            }} // Pass selected item data to the popup for setting the time and taking menu id and other required data from the list item
            customClass={"medium"}
          ></PopupView>
        )}
      </FormContainer>
    )
  );
};

export default DynamicSidebar;
