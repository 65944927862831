import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1001;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  overflow: auto;
`;

export const Header = styled.div`
  height: 70px;
  padding: 15px 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d1d5db;
  border-color: rgb(229 231 235);
  div {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex: 1;
  background-color: rgb(243 244 246);
`;
export const MainCntrols = styled.div`
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
  margin: 10px;
  gap: 20px;
  .memory {
    display: flex;
    gap: 10px;
  }
`;
export const SideHead = styled.div`
  display: grid;
  padding: 15px 1rem;
  grid-template-columns: repeat(2, auto);
  grid-gap: 10px;
  div {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
  }
`;
export const SectionContaier = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;
export const SectionContaieritem = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;
export const Sections = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 10px;
  padding: 15px 1rem;
`;
export const FormSections = styled.div`
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-gap: 10px;
  padding: 15px 1rem;
`;
export const Section = styled.div`
  border: 1px solid gray;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  flex-direction: column;
  gap: 10px;
  span {
    font-size: 12px;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;
export const Sidebar = styled.div`
  width: 300px;
  background-color: #f9fafb;
  border-right: 1px solid #d1d5db;
  padding: 0;
  overflow-y: auto;
  max-height: calc(100vh - 72px);
`;

export const RightContent = styled.div`
  flex: 1;
  padding: 0rem;
  max-height: calc(100vh - 72px);
  overflow-y: auto;
`;

export const Button = styled.button`
  background-color: #3b82f6;
  color: white;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 0.25rem;
  &:hover {
    background-color: #2563eb;
  }
`;
export const IconButton = styled.button`
  background-color: #3b82f6;
  color: white;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  border: none;
  cursor: pointer;
  width: auto;
  border-radius: 0.25rem;
  &:hover {
    background-color: #2563eb;
  }
`;
export const ContainerBox = styled.div`
  border: 1px solid #d1d5db;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #ffffff;
`;

export const Controls = styled.div`
  display: flex;
  justify-content: center;
  /* margin-bottom: 0.5rem; */
  align-items: center;
  left: auto;
  right: 0;
  position: absolute;
  top: 20px;
  z-index: 1001;
  &.side {
    top: auto;
    position: initial;
  }
  & > button {
    margin-left: 0.5rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 10px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;
  inset: auto 0px 10px;
  margin: auto auto 0px;
  padding: 0px 10px;
  inset-area: center;
  position-area: center;
  /* display: none; */
  backdrop-filter: blur(4px);
  background: rgb(255 255 255 / 83%);
  border: 1px solid lightgray;
  box-shadow: rgba(0, 0, 0, 0) -1px 0px, rgba(0, 0, 0, 0) -1px 0px, rgb(0 0 0 / 8%) -1px -2px 18px 0px;
`;

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.5rem;
`;

export const GridColumn = styled.div`
  grid-column: span ${(props) => props.width * 12};
  /* padding: 0.5rem; */
  border: 1px dashed transparent;
  position: relative;
  &:hover {
    border: 1px dashed #d1d5db;
  }
`;

export const AddElementButton = styled.button`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  background-color: #10b981;
  color: white;
  border: none;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;
  &:hover {
    background-color: #059669;
  }
`;

export const ElementBox = styled.div`
  /* padding: 0.5rem;
  margin-bottom: 0.5rem; */
  position: relative;
  /* background-color: #f3f4f6; */
  border: 1px dashed lightgray;
  min-height: 100px;
  grid-column: span ${(props) => props.gridSpan};
  &.add {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &:hover {
    border: 1px solid #d1d5db;
    .controlls {
      display: flex;
    }
  }
`;

export const ElementControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
  align-items: center;
  position: absolute;
  top: -15px;
  left: -15px;
  z-index: 1;
  & > span {
    cursor: grab;
    margin-right: auto;
  }

  & > button {
    margin-left: 0.5rem;
  }
`;

export const StyledTextarea = styled.textarea`
  width: 100%;
  height: ${(props) => (props.small ? "50px" : "100px")};
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
`;

export const ToggleContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  white-space: nowrap;
  & > button {
    flex: 1;
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const PreviewArea = styled.div`
  border: 1px solid #d1d5db;
  margin: 1rem auto;
  padding: 1rem;
  max-width: 90%;
  container: card / inline-size;
  background-color: #ffffff;
  border: 0px solid rgb(233, 233, 233);
  box-shadow: 0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  ${(props) =>
    props.view === "Mobile"
      ? `  
      width: 375px;  
      margin: 0 auto;  
      border: 1px solid #d1d5db;  
    `
      : props.view === "Tablet"
      ? `  
      width: 720px;  
      margin: 0 auto;  
      border: 1px solid #d1d5db;  
    `
      : ""}
`;

export const PlaceholderText = styled.p`
  text-align: center;
  color: #9ca3af;
`;

export const ImagePlaceholder = styled.div`
  width: 100%;
  height: 200px;
  /* background-color: #e5e7eb; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6b7280;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.25rem;
`;
export const Accordian = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const AccordianTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  transition: background-color 0.3s ease;

  &:first-child {
    border-top: none;
  }

  &:hover {
    background-color: #e5e5e5;
  }
`;

export const AccordianContent = styled.div`
  padding: 10px;
  border-top: 1px solid #e5e5e5;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #4a4a4a;
`;
export const FragDiv = styled.div`
  .add-new {
    background-color: #ffffff;
    padding: 5px 18px;
    border-radius: 4px;
    border:1px solid grey;
    cursor: pointer;
  }
`;
